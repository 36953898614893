import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SpinnerComponent from "../components/SpinnerComponent";
import FormContainer from "../components/FormContainer";
import Message from "../components/Message";
import { register } from "../actions/userActions";
import { useTranslation } from "react-i18next";

const RegisterPage = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRegister = useSelector((state) => state.userRegister);
  const redirect = searchParams.get("redirect")
    ? searchParams.get("redirect")
    : "/";

  const { error, loading, userInfo } = userRegister;

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage(t("Passwords do not match"));
    } else {
      dispatch(register(name, email, password));
    }
  };

  return (
    <FormContainer>
      <h1>{t("Sign Up")}</h1>
      {message && <Message variant="danger">{message}</Message>}
      {error && <Message variant="danger">{error}</Message>}
      {loading && <SpinnerComponent />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="name">
          <Form.Label>{t("Name")}</Form.Label>
          <Form.Control
            required
            type="name"
            placeholder={t("Enter name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>{t("Email Address")}</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder={t("Enter Email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>{t("Password")}</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder={t("Enter Password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="passwordConfirm">
          <Form.Label>{t("Confirm Password")}</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder={t("Confirm Password")}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type="submit" variant="primary">
          {t("Register")}
        </Button>
      </Form>

      <Row className="py-3">
        <Col>
          {t("Have an Account?")}{" "}
          <Link to={redirect ? `/login?redirect=${redirect}` : "/login"}>
            {t("Sign In")}
          </Link>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default RegisterPage;

