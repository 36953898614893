import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Paginate from "../components/Paginate";
import SpinnerComponent from "../components/SpinnerComponent";
import Message from "../components/Message";
import ProductCarousel from "../components/ProductCarousel";
import { listProducts } from "../actions/productActions";
import { Link } from "react-router-dom";

const HomePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get("keyword");
  const pageQuery = searchParams.get("page");
  const productList = useSelector((state) => state.productList);
  const { error, loading, products, page, pages } = productList;

  useEffect(() => {
    dispatch(listProducts(keyword, pageQuery));
  }, [dispatch, keyword, pageQuery]);

  return (
    <div>
      {!keyword && <ProductCarousel />}
      <h1>{t("ULTIMELE PRODUSE")}</h1>
      {loading ? (
        <SpinnerComponent />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div>
          <Row>
            {products.map((product) => (
              <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                <div className="my-3 p-3 rounded">
                  <Link to={`/product/${product._id}`}>
                    <img src={product.image} alt={product.name} className="card-img-top" />
                  </Link>
                  <div className="card-body">
                    <Link to={`/product/${product._id}`}>
                      <h4 className="card-title"><strong>{product.name}</strong></h4>
                    </Link>
                    <div className="my-3">
                      {product.rating} din {product.numReviews} recenzii
                    </div>
                    <h3>{product.price} Lei</h3>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Paginate pages={pages} page={page} keyword={keyword} />
        </div>
      )}
    </div>
  );
};

export default HomePage;

