// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Adaugă aici stilurile tale personalizate pentru header */
.brand {
  font-weight: bold;
  font-size: 1.5rem;
  color: #fff !important; /* Asigură-te că textul este alb */
  display: flex;
  align-items: center;
  text-transform: none !important; /* Asigură-te că textul nu este transformat în uppercase */
}

.navbar {
  background-color: #000 !important; /* Asigură-te că fundalul este negru */
}

.navbar img {
  margin-right: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA,2DAA2D;AAC3D;EACE,iBAAiB;EACjB,iBAAiB;EACjB,sBAAsB,EAAE,kCAAkC;EAC1D,aAAa;EACb,mBAAmB;EACnB,+BAA+B,EAAE,0DAA0D;AAC7F;;AAEA;EACE,iCAAiC,EAAE,sCAAsC;AAC3E;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["/* Adaugă aici stilurile tale personalizate pentru header */\n.brand {\n  font-weight: bold;\n  font-size: 1.5rem;\n  color: #fff !important; /* Asigură-te că textul este alb */\n  display: flex;\n  align-items: center;\n  text-transform: none !important; /* Asigură-te că textul nu este transformat în uppercase */\n}\n\n.navbar {\n  background-color: #000 !important; /* Asigură-te că fundalul este negru */\n}\n\n.navbar img {\n  margin-right: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
