// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main {
  min-height: 80vh;
}

h1 {
  font-size: 1.8 rem;
  padding: 1rem 0;
}
h2 {
  font-size: 1.4 rem;
  padding: 0.5rem 0;
}
h3 {
  padding: 1rem 0;
}

.rating span {
  margin: 0.1rem;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h4 {
  color: #fff;
}

.carousel img {
  display: block;
  height: 300px;
  padding: 30px;
  margin: 40px;
  border-radius: 20%;
  margin-left: auto;
  margin-right: auto;
}
.carousel a {
  margin: 0 auto;
}
@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA,aAAa;AACb;;;EAGE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,MAAM;AACR;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,aAAa;EACb,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,cAAc;AAChB;AACA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":["main {\n  min-height: 80vh;\n}\n\nh1 {\n  font-size: 1.8 rem;\n  padding: 1rem 0;\n}\nh2 {\n  font-size: 1.4 rem;\n  padding: 0.5rem 0;\n}\nh3 {\n  padding: 1rem 0;\n}\n\n.rating span {\n  margin: 0.1rem;\n}\n\n/* carousel */\n.carousel-item-next,\n.carousel-item-prev,\n.carousel-item.active {\n  display: flex;\n}\n.carousel-caption {\n  position: absolute;\n  top: 0;\n}\n\n.carousel-caption h4 {\n  color: #fff;\n}\n\n.carousel img {\n  display: block;\n  height: 300px;\n  padding: 30px;\n  margin: 40px;\n  border-radius: 20%;\n  margin-left: auto;\n  margin-right: auto;\n}\n.carousel a {\n  margin: 0 auto;\n}\n@media (max-width: 900px) {\n  .carousel-caption h2 {\n    font-size: 2.5vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
