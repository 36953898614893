import React from "react";
import { Container } from "react-bootstrap";

const DespreNoiPage = () => {
  return (
    <Container>
      <h1>Despre Noi</h1>
      <p>🎇 Rezoluție și calitate premium , NASR way este soluția!</p>
      <p>Dacă ai nevoie de următoarele, noi îți venim în sprijin:</p>
      <ul>
        <li>Banner de aproape orice dimensiune duse la rezoluție maximă✅</li>
        <li>Roll-up de prezentare la rezoluție maximă✅</li>
        <li>Cărți de vizită✅</li>
        <li>Realizare logo în toate formatele digital✅</li>
        <li>Echipamente sportive pentru cluburi sportive✅</li>
        <li>Echipamente de lucru personalizate cu logo-ul firmei tale✅</li>
        <li>Colantare auto grafică reclame cu folie pe bază de solvent ca să reziste la UV și umiditate✅</li>
        <li>Stickere auto pe bază de solvent ✅</li>
        <li>Steag dus la rezoluție maximă pe cel mai bun material și așa mai departe ✅</li>
      </ul>
      <p>📩 nasrway@yahoo.com</p>
      <p>📲 0768 101 582</p>
      <p>🧑💻 nasrway.com</p>
      
      <h2>De ce a fost ales vulturul ca și simbol și ce înseamnă de fapt Nasr</h2>
      <p>Nasr (arabă نسر, română vultur sau victorie)</p>
      
      <h3>Ce semnificație are:</h3>
      <p>Nasr este un omonim din punct de vedere semantic, adică el are două semnificații, odată el poate avea semnificație vultur, iar cea de a doua este victorie, depinde doar de pronunție.</p>
      
      <h3>De ce a fost ales vulturul ca și simbol:</h3>
      <p>Este povestea vulturilor care trăiesc pe vârfurile cele mai înalte ale munţilor Anzi, în condiţii deosebit de dure, acolo îşi au locuinţa. Şi cu toate acestea, această rasă trăieşte cel mai mult. Secretul supravieţuirii lor stă într-o enzimă protectoare emisă la baza fulgilor, astfel corpul vulturului este protejat împotriva calamităţilor în care îşi duce viaţa. Deşi condiţiile sale de viaţă sunt dintre cele mai dure, el poate atinge cele mai înalte vârfuri. Dar, ca să ajungă la acest punct, vulturul trebuie să ia o decizie grea…</p>
      
      <p>Vulturul are doar două opţiuni: să moară… sau să treacă printr-un dureros proces de transformare.</p>
      
      <p>În acel moment, pentru vultur, este imperios necesar să găsească o crăpătură pe un pisc înalt, unde să îşi încropescă un adăpost. Acolo el îşi loveşte continuu ciocul încovoiat de o stâncă până când acesta se rupe. După ce îşi rupe ciocul, el aşteaptă să îi crească cel nou apoi îşi smulge unghiile. Dupa ce noile unghii apar, începe să-şi smulgă penele îmbătrânite până la ultima. Încet, încet se îmbracă din nou cu pene tinere alimentate din belşug la bază cu preţioasa enzimă protectoare. Şi astfel, după cinci luni, vulturul îşi reia faimosul zbor pentru care a fost creat şi pentru care, de fapt, trăieşte.</p>
      
      <p>Povestea vulturului poate fi povestea noastră a fiecăruia în parte. De multe ori, ca să supravieţuim, trebuie să trecem printr-un astfel de proces. Câteodată trebuie să renunţăm la trecut, simboluri, îndeletniciri sau obiceiuri. Numai eliberându-ne de vechile obiceiuri, de vechile practici, vom putea profita de prezent privind încrezători spre viitor.</p>
    </Container>
  );
};

export default DespreNoiPage;

