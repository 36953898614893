// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ProductCarousel.css */

.product-carousel {
  background-color: #000 !important; /* Set the background color to black */
}

.product-carousel .carousel-caption {
  color: #fff; /* Ensure the text is white for better visibility */
}

`, "",{"version":3,"sources":["webpack://./src/components/ProductCarousel.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB;EACE,iCAAiC,EAAE,sCAAsC;AAC3E;;AAEA;EACE,WAAW,EAAE,mDAAmD;AAClE","sourcesContent":["/* ProductCarousel.css */\n\n.product-carousel {\n  background-color: #000 !important; /* Set the background color to black */\n}\n\n.product-carousel .carousel-caption {\n  color: #fff; /* Ensure the text is white for better visibility */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
