import React, { useEffect } from "react";
import { Button, Row, Col, ListGroup, Image, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import SpinnerComponent from "../components/SpinnerComponent";
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
} from "../actions/orderActions";
import { ORDER_PAY_RESET, ORDER_DELIVER_RESET } from "../constants/orderConst";

const OrderPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, error, loading } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }

    if (!order || successPay || order._id !== Number(id) || successDeliver) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });

      dispatch(getOrderDetails(id));
    }

    if (sessionStorage.getItem('redirectAfterReload') === 'true') {
      sessionStorage.removeItem('redirectAfterReload');
      setTimeout(() => {
        navigate("/order-success");
      }, 500); // Redirecționează după 0.5 secunde
    }
  }, [dispatch, navigate, id, successPay, successDeliver, order, userInfo]);

  const finalizeOrderHandler = () => {
    const paymentResult = {
      id: id,
      status: 'COMPLETED',
      update_time: Date.now(),
      email_address: order.user.email,
    };
    dispatch(payOrder(id, paymentResult));
    dispatch(deliverOrder(order));
    sessionStorage.setItem('redirectAfterReload', 'true');
    window.location.reload();
  };

  return loading ? (
    <SpinnerComponent />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <div>
      <h1>Comanda: {order._id}</h1>
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>Livrare</h2>
              <p>
                <strong>Nume: </strong> {order.user.name}
              </p>
              <p>
                <strong>Email: </strong>
                <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
              </p>
              <p>
                <strong>Adresă de livrare: </strong>
                {order.shippingAddress.address}, {order.shippingAddress.city}
                {"  "}
                {order.shippingAddress.postalCode},{"  "}
                {order.shippingAddress.country}
              </p>

              {order.isDelivered ? (
                <Message variant="success">
                  Livrat la {order.deliveredAt}
                </Message>
              ) : (
                <Message variant="warning">Nu a fost livrat</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Metodă de plată</h2>
              <p>
                <strong>Metodă: </strong>
                {order.paymentMethod}
              </p>
              {order.isPaid ? (
                <Message variant="success">Plătit la {order.paidAt}</Message>
              ) : (
                <Message variant="warning">Nu a fost plătit</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Produse comandate</h2>
              {order.orderItems.length === 0 ? (
                <Message variant="info">Comanda ta este goală</Message>
              ) : (
                <ListGroup variant="flush">
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>

                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>

                        <Col md={4}>
                          {item.qty} X {item.price} Lei = {(item.qty * item.price).toFixed(2)} Lei
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>

        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Sumar Comandă</h2>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Produse:</Col>
                  <Col>{order.ItemsPrice} Lei</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Taxe:</Col>
                  <Col>{order.taxPrice} Lei</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Total:</Col>
                  <Col>{order.totalPrice} Lei</Col>
                </Row>
              </ListGroup.Item>
              
              <ListGroup.Item>
                <Button
                  type="button"
                  className="btn btn-block"
                  onClick={finalizeOrderHandler}
                >
                  Finalizează Comanda
                </Button>
              </ListGroup.Item>
            </ListGroup>
            {loadingDeliver && <SpinnerComponent />}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default OrderPage;

